<template>
  <div>
    <v-dialog v-model="showDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h6">Publish to site</v-card-title>
          <v-card-text>
            All pages will be publish to site. Are you sure ?
          </v-card-text>
          <v-card-actions class="action-btn-wrapper">
            <v-btn text @click="goToPreview" color="secondary">
              Preview
            </v-btn>
            <div>
              <v-btn text @click="goToDashboard">
              No
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="getTemplateLoading"
              @click="publish(false)"
            >
              Yes
            </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <div v-if="confirmed && !done" class="center-custom">
      <v-progress-circular indeterminate
        color="primary"
        size="150"
      > {{ preview ? 'Loading Preview' : 'Publishing' }} </v-progress-circular>
    </div>
    <div v-if="done" class="center-custom">
      <sweetalert-icon v-if="published" icon="success" class="sweetalert-icon-custom" color="#7c4579"></sweetalert-icon>
      <sweetalert-icon v-else icon="warning" class="sweetalert-icon-custom"></sweetalert-icon>
    </div>
    <div v-if="done && !published" class="retry-btn-wrapper">
      <v-btn color="primary" @click="publish(true)">retry</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SweetalertIcon from 'vue-sweetalert-icons';
import store from "@/store";

export default {
  components: { SweetalertIcon },
  data() {
    return {
      preview: false,
      showDialog: true,
      confirmed: false,
      done: false,
      published: false
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(["generateTemplate"]),
    async goToPreview() {
      await this.publish(true);
      this.showDialog = true;
      this.confirmed = false;
      this.done = false;
    },
    goToDashboard() {
      this.showDialog = false;
      this.$router.push("/app/dashboard");
    },
    async publish(preview) {
      try {
        this.preview = preview;
        this.showDialog = false;
        this.confirmed = true;
        this.done = false;
        await this.generateTemplate(preview);
        this.done = true;
        this.published = !this.getTemplateError;
        if (!preview) {
          store.dispatch("showSnackbar", 'Published');
        } else {
          window.open('/api/output/index.html', '_blank');
        }
      } catch (error) {

      }
    }
  },
  computed: {
    ...mapGetters(["getTemplateLoading", "getTemplateError"])
  }
};
</script>

<style lang="scss" scoped>
  .action-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .sweetalert-icon-custom {
    transform: scale(1.5);
  }

  .retry-btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .center-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    .publish-icon {
      font-size: 150px;
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }
</style>
